import React from 'react';
import axios from 'axios';
import { BrowserRouter as Router, Route, Link, RouteComponentProps } from "react-router-dom";
import { any, number } from 'prop-types';
import configData from "../../config.json";
import CookieService from '../../services/CookieService';
import UrlService from '../../services/UrlService';
import LibService from '../../services/LibService';
import { Column } from '@syncfusion/ej2-grids';
import {Helmet} from 'react-helmet'; 
import { MdFormatAlignLeft } from 'react-icons/md';
import { Editor, OriginalTools } from 'react-bootstrap-editor';
import { Container, Row, Col, Form, Button } from 'react-bootstrap';
import queryString from 'query-string';
import moment from 'moment';
//File code này chưa dùng ở đâu cả nhưng cứ để vậy cho đến khi cần cập nhật thông tin chi tiết của user sẽ dùng
class UpdateUser extends React.Component<RouteComponentProps, any> {
    constructor(props: RouteComponentProps) {
        super(props);
        const query = queryString.parse(window.location.search);
        this.state = {
            Users: { userName: query.u, visible:false,  softId: configData.SoftId }
        };
         //this.handleChangeUserName = this.handleChangeUserName.bind(this);
         //this.handleChangePassWord = this.handleChangePassWord.bind(this);
         //this.handleChangeRememberMe = this.handleChangeRememberMe.bind(this);
         this.submitHandler = this.submitHandler.bind(this);
      }
    changeHandler = (e: any) => {
        let Users = this.state.Users;
        Users[e.target.name] = e.target.value;
        this.setState({ Users: Users });
    }
      submitHandler(evt: any) {
          evt.preventDefault();
          const query = queryString.parse(window.location.search);
          axios.post(configData.serviceUrl + '/Admin/UpdateUser', this.state.Users,
        { headers: { 'Content-Type': 'application/json' ,
            'Access-Control-Allow-Origin': '*',
            'Access-Control-Allow-Headers': 'X-Requested-With',
            'Authorization': CookieService.get("access_token"),
                   }
        }).then(response =>{
            if (response) {
                if (response.status === 200) {
                    if (response.data.sErr && response.data.sErr.errorCode != 0) {
                        alert("Cấp bản quyền thất bại! Lỗi: " + response.data.sErr.errorMessage);
                    }
                    else {
                        //console.log(response.data.Data);
                        alert("Cấp bản quyền thành công!");
                        window.location.href = UrlService.UrlRoot() + "/ChiTietTaiKhoan?u=" + query.u;
                    }

                }
                else {
                    alert("Cấp bản quyền thất bại! Lỗi: " + response.data.sErr.errorMessage);
                }
            }
            else {
                alert("Cấp bản quyền thất bại!");
            }
        })
        .catch(error =>{
            console.log(error);
            alert(error);
        })
    }
    
      submitHandlerCall=()=>{
      }
      componentDidMount() {
      }
    render() {
        const titleWidth = 4, editorWidth=8;

        return (
            <div className="container"  >
                <Helmet>
					<title>{configData.CompanyName} - Cấp bản quyền</title>
					<meta name="description" content={ configData.Description } />
            	</Helmet>
                <section className="inner-page" style={{ marginTop: "72px" }}>
                    <form className ="login-form" onSubmit={this.submitHandler} >
                        <Container>
                            <Row>
                                    <div className="col-lg-4">
                                        <div className="imgcontainer">
                                            <div className="avatar"></div>
                                        </div>
                                    </div>
                                <div className="col-lg-8">
                                    <Form>
                                        <Form.Group as={Row} className="mb-3" >
                                            <Form.Label column sm={titleWidth} >Tên đăng nhập</Form.Label>
                                            <Col sm={editorWidth}> <Form.Control name="userName" type="text" size="lg" defaultValue={this.state.Users.userName} onChange={this.changeHandler} />   </Col>
                                        </Form.Group>   
                                        <Form.Group as={Row} className="mb-3" >
                                            <Form.Label column sm={titleWidth} >Hạn sử dụng</Form.Label>
                                            <Col sm={editorWidth}> <Form.Control name="dateTo" type="date" size="lg" defaultValue={this.state.Users.dateTo} onChange={this.changeHandler} />   </Col>
                                        </Form.Group>
                                        <Button variant="primary" type="submit" onClick={this.submitHandler}  >
                                            Cấp bản quyền
                                        </Button>
                                    </Form>
                                    </div>
                            </Row>
                        </Container>
                    </form>
                </section>
            </div>
        );
    }

}

export default UpdateUser;
