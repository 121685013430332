import React from 'react';
import { any, number } from 'prop-types';
import axios from 'axios';
import configData from '../../config.json';
import ReCAPTCHA from "react-google-recaptcha";
import UrlService from '../../services/UrlService';
import {Helmet} from 'react-helmet'; 

class LossPassSubmit extends React.Component<any, any> {
  private dateValue:Date=new Date();
  private pass2Style;
    constructor(props: any) {
        super(props);
        this.state = {
          loss : {
                userName: '',
                submitCode:'',
            softId: configData.SoftId
          },
            notice: any
        };
        
    }
    
      changeHandler=(e: any)=>{
        let loss = this.state.loss;
        loss[e.target.name]= e.target.value;
        this.setState({loss: loss});
      }
      submitHandler = (e: any) =>{
        e.preventDefault();
        // console.log(this.state.register)
        axios
            .post(configData.serviceUrl + '/Users/ForgotPasswordSubmit', this.state.loss)
        .then(response =>{
          if(response && response.status===200)
          {
            if(response.data.resetOk && response.data.sErr==null)
            {
                window.location.href = UrlService.UrlRoot() + "/DangNhap";
            }
            else  
            {
              this.setState( { notice : <label style={{ marginLeft: "5px", color:"Red" }} > {response.data.sErr.errorMassage}</label>} );
              //alert(response.data.Message);
            }
        }
        else
        {
            this.setState( { notice : <label style={{ marginLeft: "5px", color:"Red" }} > {"Không thể kết nối với API"}</label>} );
        }
    }
        ).catch(error =>{
          alert(error);
        })
      }
      onChange =(value)=> {
        // console.log(value );
         let loss = this.state.loss;
         loss["Token"]= value;
        this.setState({loss: loss});
        //console.log(this.state.register);
      }
      
    render(){
      const {
          userName,
          submitCode,
         softId
      } = this.state.loss;
      const notice= this.state.notice;
      //const recaptchaRef = React.createRef();
        return (
        <div className="container" style={{ marginTop: "90px" , minHeight:"68vh", marginBottom: "20px" , width: "800px" }}>
          <Helmet>
            <title>{configData.CompanyName} - Quên mật khẩu - Xác minh</title>
            <meta name="description" content={ configData.Description } />
          </Helmet>
            <form action="/" onSubmit={this.submitHandler}>
              <h1>Xác nhận khôi phục mật khẩu</h1>
              <p>Vui lòng cung cấp Tên đăng nhập hoặc Email đăng ký và Mã PIN được gửi qua Email.</p>
              <div className="form-group">
                <label htmlFor="exampleInputEmail1">Tên đăng nhập/Email</label>
                        <input name="userName" required value={userName} onChange={this.changeHandler} type="text" className="form-control" id="exampleInputEmail1" aria-describedby="emailHelp" placeholder="Nhập tên đăng nhập hoặc Email đăng ký" />
                        <input name="submitCode" required value={submitCode} onChange={this.changeHandler} type="text" className="form-control" id="exampleInputEmail1" aria-describedby="emailHelp" placeholder="Nhập mã pin được nhận qua Email" />
                
              </div>
              
              <button type="submit" className="btn btn-primary">Gửi</button>
              {notice}
              {/* <button type="button" className="btn btn-primary">Trang chủ</button> */}
            </form>
         </div>   
           
        );
    }

}

export default LossPassSubmit;
