import React from 'react';
// import 'bootstrap/dist/css/bootstrap.min.css';
// import './SignUp.css';
import { any, number } from 'prop-types';
import axios from 'axios';
import configData from '../../../config.json';
import { DatePickerComponent } from '@syncfusion/ej2-react-calendars';
import ReCAPTCHA from "react-google-recaptcha";
import { T } from 'antd/lib/upload/utils';
import {Helmet} from 'react-helmet';
import { Button } from 'react-bootstrap';
import CookieService from '../../../services/CookieService';

class GlobalConfig extends React.Component<any, any> {
  private dateValue:Date=new Date();
    constructor(props: any) {
        super(props);
        this.state = {
          register : [{
            ID: '',
            VALUE: ''
          }],
            notice: any
        };
        
    }
    
      changeHandler=(e: any)=>{
        let register = this.state.register;
        register[e.target.name]= e.target.value;
        this.setState({register: register});
      }
      changePass2Handler=(e: any)=>{
        let register = this.state.register;
        if(register["Password"]!== e.target.value)
        {
          this.setState({notice:  <label style={{ marginLeft: "5px", color:"Red" }} > Nhập hai lần mật khẩu không giống nhau </label> });   
        }
        else
        {
          this.setState({notice: ""});
        }
      }
      submitHandler = (e: any) =>{
        e.preventDefault();
        // console.log(this.state.register)
        axios
        .post(configData.serviceUrl + '/api/Users/Register', this.state.register)
        .then(response =>{
          if(response && response.status===200)
          {
            if(response.data.Status===201)
            {
                this.setState( { notice : <label style={{ marginLeft: "5px", color:"Green" }}>Đăng ký thành công</label>} );
                //alert("Chúc mừng đăng ký thành công. Tiến hành đăng nhập");
                //window.location.href= window.location.origin + '/login';
            }
            else  
            {
              this.setState( { notice : <label style={{ marginLeft: "5px", color:"Red" }} > {response.data.Message}</label>} );
              //alert(response.data.Message);
            }
        }}
        ).catch(error =>{
          alert(error);
        })
      }
      onChange =(value)=> {
        // console.log(value );
         let register = this.state.register;
        register["Token"]= value;
        this.setState({register: register});
        //console.log(this.state.register);
      }
      onFileUpload = () => {
        const formData = new FormData();
        // Update the formData object
        formData.append(
        "files",
        this.state.selectedFile
        );
        // Details of the uploaded file
        console.log(this.state.selectedFile);
        // Request made to the backend api
        // Send formData object
        fetch(
            configData.serviceUrl+ '/api/Admin/Upload?ModunName=Config',
            {
                method: 'POST' ,
                body: formData,
                headers:new Headers ({
                'Access-Control-Allow-Origin' : '*',
                'Authorization' : CookieService.get("access_token") 
               
                })
            }
        
        )
        .then(response => response.json())
        .then(rss => {
            if(rss.Status===200)
            {
                let dataJs = this.state.dataJs;
                dataJs["TEXT1"]= rss.Data.NameFile;
                this.setState({dataJs: dataJs, imageUrl: rss.Data.NameFile});
                alert("Upload xong!");
            }
            else
            {
                alert("Upload lỗi: " + rss.Message);
            }
        })
        .catch(e => {
            console.log(e);
        });
            
           
    };
    onFileChange = event => {
    
      // Update the state
      this.setState({ selectedFile: event.target.files[0] });
    
    };
    fileData = () => {
            
      if (this.state.selectedFile) {
      
      return (
          <div>
          <h2>Chi tiết file:</h2>
                  
          <p>File Name: {this.state.selectedFile.name}</p>
          <p>File Type: {this.state.selectedFile.type}</p>
          </div>
           );
      } 
      else
       {
           return (
                  <div>
                  <br />
                  <h4>Chọn file để upload</h4>
                  </div>
              );
       }
  };
    render(){
      const img1 = {
        width : '130px',
        height : '105px',
        resize : 'both'
      }  as const;
      const {
         Email,
         Password,
         Name ,
         Username,
         Token
      } = this.state.register;
      const notice= this.state.notice;
      const recaptchaRef = React.createRef();
        return (
        <div className="container" style={{ marginTop: "10px" , marginBottom: "10px" , width: "100%" }}>
          <Helmet>
            <title>{configData.CompanyName} - Thiết lập chung</title>
            <meta name="description" content={ configData.Description } />
          </Helmet>
            <form action="/" onSubmit={this.submitHandler}>
              <h1>Thiết lập chung</h1>
              <div className="form-group">
                <label htmlFor="exampleInputEmail1">Hình ảnh Banner</label>
                <img src={"/Upload/"+this.props.ModunName+"/"+this.state.imageUrl} style={img1}></img> 
                            <br/>
                            <br/>
                            <div>
                                    <input type="file" onChange={this.onFileChange} />
                                    <Button   onClick={this.onFileUpload}>
                                    Upload...
                                    </Button >
                            </div>
                            {this.fileData()}
              </div>
              <button type="submit" className="btn btn-primary">Lưu</button>
              {notice}
            </form>
         </div>   
           
        );
    }

}

export default GlobalConfig;
