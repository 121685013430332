import React from 'react';
import UrlService from '../../services/UrlService';
import BootstrapTable from 'react-bootstrap-table-next';
import cellEditFactory , { Type } from 'react-bootstrap-table2-editor';
import filterFactory, { textFilter, numberFilter, dateFilter } from 'react-bootstrap-table2-filter';
import paginationFactory from 'react-bootstrap-table2-paginator';
import CookieService from '../../services/CookieService';
import { Button, Card, ListGroup, ListGroupItem } from 'react-bootstrap';
import {  Container, Row,Col } from 'react-bootstrap';
import { TiDeleteOutline } from 'react-icons/ti';
import BreadCrumbNews from '../Commons/BreadCrumbNews';
import '../GioHang/GioHang.css'; 
import axios from 'axios';
import configData from '../../config.json';
import queryString from 'query-string';
import AccountService from '../../services/AccountService';

class TaiKhoan extends React.Component<any, any> {
	node: any;
	constructor(props:any) {
        super(props);
		this.state = {
			dataJs: [],
		}
		// this.deleteProduct = this.deleteProduct.bind(this);
	}
	componentDidMount() {
		const Token = CookieService.get("access_token");
		//const Roles= AccountService.Roles();
		if(Token)
		{
			fetch(
				configData.serviceUrl + '/Admin/GetAccountList?SoftId=' + configData.SoftId,
				{headers:new Headers ({
					'Access-Control-Allow-Origin' : '*',
					'Authorization' :  CookieService.get("access_token") ,
				})}
			) 
				.then(response => response.json())
				.then(dataJs => {
					if(dataJs.sErr==null)
					{
					this.setState({
						dataJs: dataJs.accounts
					});
					}
				})
				.catch(e => {
					console.log(e);
				});
		}
		else	
		{
			window.location.href= UrlService.UrlRoot()+"/DangNhap";
		}
        
      }

	componentWillMount(){
		
	}

	
	
	actionsFormatter (cell, row ,rowIndex, {parents})
	{
		return <ul className="list-inline m-0">
	
		<li className="list-inline-item">
			<button className="btn btn-danger btn-sm rounded-0" onClick={ () => 
			{
				//console.log(row.userId);
				//console.log(parents[rowIndex].NO_VOU);
				window.location.href = UrlService.UrlRoot()+"/ChiTietTaiKhoan?u=" + row.userName;
			}
		}
			type="button" data-toggle="tooltip" data-placement="top" title="Chi tiết đơn hàng">
			<i className="fa fa-external-link"></i>
			</button>
		</li>
	</ul>
	}
    render(){
		// let dataCookie= CookieService.get("orderData");
		// this.setState({ dataJs : dataCookie});
		const dataJs= this.state.dataJs;
      
		function priceFormatter(cell, row) {
			return (
			  <span>{parseInt(cell).toLocaleString() }</span>
			);
		}
		 
        const columns = [
			{
                dataField: 'userId',
                text: 'Mã tài khoản',
				sort: true,
				filter: textFilter(),
				editable: false
			},
			{
				dataField: 'userName',
				text: 'Tên đăng nhập',
				sort: true,
				headerStyle: () => {
					return { width: "10px" };
				  },
				filter: textFilter(),
				editable: false
			},
			{
				dataField: 'email',
				text: 'Email',
				headerStyle: () => {
					return { width: "10%" };
				  },
				sort: true,
				filter: textFilter(),
				editable: false
			},
			{
				dataField: 'phone',
				text: 'Điện thoại',
				headerStyle: () => {
					return { width: "10px" };
				  },
				filter: textFilter(),
				sort: true,
				editable: false
			},
            {
				dataField: 'dateFrom',
				text: 'Ngày đăng ký',
				headerStyle: () => {
					return { width: "5px" };
				  },
				editable: false,
				filter: dateFilter(),
				formatter: (cell) => {
					let dateObj = cell;
					if (typeof cell !== 'object') {
						dateObj = new Date(cell);
					}
					return `${('0' + dateObj.getUTCDate()).slice(-2)}/${('0' + (dateObj.getUTCMonth() + 1)).slice(-2)}/${dateObj.getUTCFullYear()}`;
				}
			},
			{
				dataField: 'dateTo',
				text: 'Ngày hết hạn',
				editable: false,
				width:'20px',
				
				filter: dateFilter(),
				formatter: (cell) => {
					let dateObj = cell;
					if (typeof cell !== 'object') {
						dateObj = new Date(cell);
					}
					return `${('0' + dateObj.getUTCDate()).slice(-2)}/${('0' + (dateObj.getUTCMonth() + 1)).slice(-2)}/${dateObj.getUTCFullYear()}`;
				}
			}
			,{
				dataField: 'visible',
				text: 'Tình trạng',
				editable: false,
				formatter: (cell) => {
					let visible = cell;
					
					return `${visible? 'Đang hoạt động': 'Đã khóa'}`;
				}
			},
			{
				dataField: 'activeDeviceNumber',
				text: 'Số máy',
				filter: textFilter(),
				sort: true,
				editable: false
			},
			, {
				dataField: 'isApprove',
				text: 'Bản quyền',
				editable: false,
				formatter: (cell) => {
					let visible = cell;

					return `${visible ? 'Đã cấp bản quyền' : 'Chưa đăng ký bản quyền'}`;
				}
			}
			,{
				dataField: 'actions',
				text: 'Thao tác',
				isDummyField: true,
				csvExport: false,
				formatter: this.actionsFormatter,
				formatExtraData: { parents: this.state.dataJs },
				editable: false
			  }
            
		];

		const customTotal = (from, to, size) => (
			<span className="react-bootstrap-table-pagination-total">
			  Showing {from} to {to} of {size} Results
			</span>
		  );
		  const rowStyle={ height: "50px"  };
		  const options = {
			paginationSize: 10,
			pageStartIndex: 1,
			firstPageText: 'First',
			prePageText: 'Previous',
			nextPageText: 'Next',
			lastPageText: 'Last',
			nextPageTitle: 'First page',
			prePageTitle: 'Pre page',
			firstPageTitle: 'Next page',
			lastPageTitle: 'Last page',
			showTotal: true,
			paginationTotalRenderer: customTotal,
		  };

		  function afterSaveCell(oldValue, newValue, row, column) {
			console.log(row);
			console.log(column);
			try{
				 
				
			}
			catch(err) {
				console.log("Lỗi lưu: "+err);
			}
		}

        return (
			<div className="_container"  >
			<section className="inner-page">
			   <div>
				{/* <BreadCrumbNews backgroundImage="../assets/img/bg-pheader.jpg"></BreadCrumbNews> */}
			   </div>
			   <div style={{paddingTop: '90px', paddingBottom:'110px'}}>
	  
			   <Container >
				   <Row  >
					 <Col sm={12}> 
						<BootstrapTable
							ref={ n => this.node = n }
							keyField='userId' 
							data={dataJs} 
							columns={ columns } 
							hover
							pagination={paginationFactory(options)}
							condensed
							noDataIndication="Chưa có dữ liệu"
							cellEdit={ cellEditFactory({
								mode: 'click',
								autoSelectText: true,
								blurToSave: true
								,afterSaveCell
							}) }
						
							filter={ filterFactory() }
					
							wrapperClasses="table-responsive"
							rowStyle={ rowStyle }
							
							//rowEvents={ rowEvents }
							// expandRow={ expandRow }
						/>
					 </Col>
				 
				   </Row>
				   
			   </Container>
			   </div>
			 </section>
		 </div>
			 
        );
    }

}

export default TaiKhoan;
