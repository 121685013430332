import React from 'react';
import queryString from 'query-string';
import { Link } from 'react-router-dom';
import configData from "../../config.json";
import { Container, Row } from 'react-bootstrap';
import LastNews from './LastNews';
import LastNewsFullWidth from './LastNewsFullWidth';
import ImageView from '../Commons/ImageView';

class Banner extends React.Component<any, any> {
	constructor(props:any) {
        super(props);
	}
	componentDidMount() {
      }
	componentWillMount(){
	}
    render(){
        return (
            <div className="container">
               {/* <ImageView src="/img/banner.jpg" /> */}

               <div className="row">
                    <div className="col-lg-6 d-flex flex-column justify-content-center pt-4 pt-lg-0 order-2 order-lg-1" data-aos="fade-up" data-aos-delay="200">
                    <h1>Hệ thống quản lý khách hàng</h1>
                    <h2>Phát triển bởi Soft Việt</h2>
                    <div className="d-lg-flex">
                        <a href="https://softviet.vn" target="_blank"  className="btn-get-started scrollto">Tư vấn & Báo giá</a>
                        
                    </div>
                    </div>
                    <div className="col-lg-6 order-1 order-lg-2 hero-img" data-aos="zoom-in" data-aos-delay="200">
                        <img src="assets/img/logo-header.png" className="img-fluid animated" alt="" />
                    </div>
                </div>
            </div>
        );
    }

}

export default Banner;
