import React from 'react';
import configData from "../../../config.json";
import CookieService from '../../../services/CookieService';
import BootstrapTable from 'react-bootstrap-table-next';
import cellEditFactory , { Type } from 'react-bootstrap-table2-editor';
import { Button } from 'react-bootstrap';


class TreeListObjectGroup extends React.Component<any, any> {
	node: any;
	
	constructor(props:any) {
        super(props);
		this.state = {
			dataJs: [],
			ID_OTY: 0,
			ID_OGR: 0
		}
	}
	componentDidMount() {
         
        fetch(
			configData.serviceUrl+ '/api/Admin/GetObjectGroupList?ID_OTY='+ this.props.ID_OTY ,
			{headers:new Headers ({
				'Access-Control-Allow-Origin' : '*',
                'Authorization' : CookieService.get("access_token") 
			})}
        
        ) 
		    .then(response => response.json())
            .then(dataJs => {
				this.setState({
					dataJs: dataJs.Data
				});
			})
            .catch(e => {
                console.log(e);
				
               
            });
      }

	componentWillMount(){
		
	}

	handleAddRow = () => {
		 
		var row = {
			ID_OGR: 0,
			ID_OGRP: null,
			ID_OTY: this.props.ID_OTY,
			NAME_OGR: " ",
			GROUP_OGR: 0,
			VISIBLE: true,
			CODE: "",
			ORDER_OGR: 0,
			ID_OGR2: null,
			ID_ID_OGR: null
		}
		this.state.dataJs.push(row);
		this.setState({
			dataJs: this.state.dataJs
		});
	}

	handleDeleteData = () => {
		try{
           if(this.node.selectionContext.selected.length>0)
		   {
			var result = window.confirm("Có đồng ý xóa "+ this.node.selectionContext.selected.length + " dòng đang chọn?");
			if (result) {
			    
				 this.node.selectionContext.selected.forEach(element => 
					{
						try{
						 
							fetch(
								configData.serviceUrl+ '/api/Admin/ObjectGroupDetail',
								{
									method: 'DELETE' ,
									body: '{ "ID_OGR": __VL__ }'.replace("__VL__",element),
									headers: new Headers ({
									'Access-Control-Allow-Origin' : '*',
									'Authorization' :  CookieService.get("access_token") ,
									'Content-Type' : 'application/json'
									})
								}
							) 
								.then(response => response.json())
								.then(dataJs => {
									//console.log(dataJs);
									window.location.reload();
								})
								.catch(e => {
									console.log(e);
									
								});

						}
						catch(err) {
							console.log("Lỗi xóa: "+err.message);
						}
					}
					);
                   
					//window.location.reload();
			}
			
		   }
		}
		catch(err) {
			console.log("Lỗi xóa dữ liệu: "+err.message);
		}
	}

    render(){
        
		const btn1 = {
			width : '100px'
		  }  as const;
		  const btn2 = {
			width : '200px',
			marginLeft : '10px'
		  }  as const;

		const headerSortingStyle = { backgroundColor: '#c8e6c9' };

		const columns = [{
			dataField: 'NAME_OGR',
			text: 'Tên',
			sort: true,
			validator: (newValue, row, column) => {
				if (!newValue) {
				  return {
					valid: false,
					message: 'Bắt buộc nhập giá trị'
				  };
				}
				 
				return true;
			  }
			  ,headerSortingStyle
		  }
		  ,{
			dataField: 'CODE',
			text: 'Mã code'
		  }
		  ,{
			  dataField:'ORDER_OGR',
			  text:'Thứ tự',
			  type: 'number',
			  sort: true,
			  headerSortingStyle
		  }
		  ,{
			dataField: 'VISIBLE',
			text: 'Hiển thị?',
			sort: true,
			type: 'bool',
			editor: {
				type: Type.CHECKBOX,
				value: 'true:false'
			  }
		  }
		];
       
		

		function beforeSaveCell(oldValue, newValue, row, column, done) {
			setTimeout(() => {
				done(true);
				//console.log("Trước khi lưu");
			  //if (confirm('Do you want to accep this change?')) {
			//	done(true);
			  //} else {
			//	done(false);
			 // }
			}, 0);
			return { async: true };
		}

		function afterSaveCell(oldValue, newValue, row, column) {
			//console.log(row);
			//console.log(column);
			try{
				 
				fetch(
					configData.serviceUrl+ '/api/Admin/ObjectGroupDetail',
					{
						method: 'POST' ,
						body: JSON.stringify(row),
						headers: new Headers ({
						'Access-Control-Allow-Origin' : '*',
						'Authorization' :  CookieService.get("access_token") ,
						'Content-Type' : 'application/json'
						})
						
				    }
				) 
					.then(response => response.json())
					.then(dataJs => {
						//console.log(dataJs);
						if(row.ID_OGR===0)
						{
							window.location.reload();
						}
					})
					.catch(e => {
						console.log(e);
					});
			}
			catch(err) {
				console.log("Lỗi lưu: "+err.message);
			}
		}

		const defaultSorted = [{
			dataField: 'ORDER_OGR',
			order: 'asc'
		  }];
        return (

				<div id="div_list_content" className="yaallahaa-news-grids-agile">
					<Button variant="primary" style={btn1} onClick={this.handleAddRow} >Thêm</Button>
					<Button variant="primary" style={btn2}  onClick={ this.handleDeleteData }>Xóa dòng đang chọn</Button>
					 
                     <BootstrapTable
					    ref={ n => this.node = n }
						keyField='ID_OGR' 
						data={this.state.dataJs} 
						columns={ columns } 
						hover
						condensed
						noDataIndication="Chưa có dữ liệu"
						cellEdit={ cellEditFactory({
							mode: 'dbclick',
							autoSelectText: true,
							blurToSave: true
							,afterSaveCell
						}) }
						selectRow={ { mode: 'radio', clickToSelect: true,clickToEdit: true } }
						defaultSorted={ defaultSorted } 
						
					 />
				</div>
        );
    }

}

export default TreeListObjectGroup;
