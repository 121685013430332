import React from 'react';
import {  Breadcrumb } from 'react-bootstrap';
import configData from "../../config.json";
import '../Commons/BreadCrumb.css';

class BreadCrumbs extends React.Component<any, any> {
	
	constructor(props:any) {
        super(props);
		this.state = {
			dataJs: [],
			ID_OTY: 0,
			ID_OGR: 0
		}
	}
	componentDidMount() {
         
        fetch(
			configData.serviceUrl+ '/api/News/BreadCrumbs?Url='+ window.location.href  ,
			{headers:new Headers ({
				'Access-Control-Allow-Origin' : '*'
			})}
        
        ) 
		    .then(response => response.json())
            .then(dataJs => {
				this.setState({
					dataJs: dataJs.Data
				});
			})
            .catch(e => {
                console.log(e);
				
               
            });
      }

	componentWillMount(){
	 
	}

    render(){
        
		const divTop = {
			
		  }  as const;

		  const breadcrumbs = {
			padding: '1px 0',
			background: '#f3f5fa',
			minHeight: '40px',
			marginTop : '66px'
		  }  as const;

		  let rs;
          rs=  this.state.dataJs.map((itemB: any, index: number) => (
				   <Breadcrumb.Item key={itemB.Link}  href={itemB.Link} active={itemB.IsActive} >{itemB.Caption}</Breadcrumb.Item>
		  ));
			

        return (
			  <div style={breadcrumbs}>
				<Breadcrumb className="container" >
						{rs}				
				</Breadcrumb>
       		 </div>
        );
    }

}

export default BreadCrumbs;
