import React from 'react';
import configData from "../../config.json";
import ProjectFilter from "../SanPham/ProjectFilter";
import ProductsList from './ProductsList';
import ProductsHeader from './ProductsHeader';
import {Helmet} from 'react-helmet'; 
 

class Products extends React.Component<any, any> {
	
	constructor(props:any) {
        super(props);
		this.state = {
			dataJs: [],
			ID_OTY: 0,
			ID_OGR: 0
			 
		}
	}
	componentDidMount() {
      }

	componentWillMount(){
		 
	}

    render(){
        const flexContainer = {
			display: 'flex',
			flexFlow: 'row wrap',
			justifyContent: 'space-around',
			padding: '0',
			margin: '0',
			listStyle: 'none'
		  }  as const;
        
		  const flexItem = {
			padding: '5px',
			width: '50%',
		 
			marginTop: '10px',
			 
			listStyle: 'none'
		  }  as const;

        return (
			<div id="project-filter-wrapper">
				<Helmet>
					<title>{configData.CompanyName} - Danh sách sản phẩm</title>
					<meta name="description" content={ configData.Description } />
            	</Helmet>
				<ProductsHeader/>
				<ProjectFilter ID_OTY="1" />
				<ProductsList/>
			</div>
        );
    }

}

export default Products;
