import React from 'react';
import { any, number } from 'prop-types';
import axios from 'axios';
import configData from '../../config.json';
import ReCAPTCHA from "react-google-recaptcha";
import UrlService from '../../services/UrlService';
import {Helmet} from 'react-helmet'; 
import CookieService from '../../services/CookieService';

class ChangePass extends React.Component<any, any> {
  private dateValue:Date=new Date();
  private pass2Style;
    constructor(props: any) {
        super(props);
        this.state = {
          change : {
            userName: '',
            currentPassword:'',
            newPassword:'',
            newPasswordConfim:'',
            softId: configData.SoftId
          },
            notice: any
        };
        
    }
    componentDidMount() {
      const Token = CookieService.get("access_token");
      if(Token)
      {
        let change = this.state.change;
        change["userName"]= CookieService.get("Username");
        this.setState({change: change});
      }
      else	
      {
        window.location.href= UrlService.UrlRoot()+"/DangNhap";
      }
          
        }
      changeHandler=(e: any)=>{
        let change = this.state.change;
        change[e.target.name]= e.target.value;
        this.setState({change: change});
      }
      submitHandler = (e: any) =>{
        e.preventDefault();
        console.log(this.state.change);
        console.log(configData.serviceUrl);
        axios
            .post(configData.serviceUrl + '/Users/ChangePassword', this.state.change,
            { headers: { 'Content-Type': 'application/json' ,
            'Access-Control-Allow-Origin': '*',
            'Authorization': CookieService.get("access_token"),
                   }
        })
        .then(response =>{
          //console.log(response);
          //console.log(response.data.sErr.errorMessage);
          if(response && response.status===200)
          {
            if(response.data.changeOk && response.data.sErr==null)
            {
                window.location.href = UrlService.UrlRoot() + "/ChangePassSuccess";
            }
            else  
            {
              this.setState( { notice : <label style={{ marginLeft: "5px", color:"Red" }} > {response.data.sErr.errorMessage}</label>} );
              //alert(response.data.Message);
            }
        }
        else
        {
            this.setState( { notice : <label style={{ marginLeft: "5px", color:"Red" }} > {"Không thể kết nối với API"}</label>} );
        }
    }
        ).catch(error =>{
          alert(error);
        })
      }
      onChange =(value)=> {
        // console.log(value );
         let change = this.state.change;
         change["Token"]= value;
        this.setState({change: change});
        //console.log(this.state.register);
      }
    BodyRender()
    {
      const {
        userName,
        currentPassword,
        newPassword,
        newPasswordConfim,
         softId
      } = this.state.change;
      const notice= this.state.notice;
      return (
        <div className="container" style={{ marginTop: "90px" , minHeight:"68vh", marginBottom: "20px" , width: "800px" }}>
          <Helmet>
            <title>{configData.CompanyName} - Quên mật khẩu</title>
            <meta name="description" content={ configData.Description } />
          </Helmet>
            <form action="/" onSubmit={this.submitHandler}>
              <h1>Đổi mật khẩu</h1>
              <p>Vui lòng cung cấp Mật khẩu đang sử dụng và Mật khẩu mới để thay đổi mật khẩu.</p>
              <div className="form-group">
                <label htmlFor="exampleInputEmail1">Tên đăng nhập/Email</label>
                <input name="userName" readOnly  required value={userName} onChange={this.changeHandler} type="text" className="form-control" id="exampleInputEmail1" aria-describedby="emailHelp" placeholder="Nhập tên đăng nhập hoặc Email đăng ký" />
              </div>
              <div className="form-group">
                <label htmlFor="currentPassword">Mật khẩu hiện tại</label>
                <input name="currentPassword" required value={currentPassword} onChange={this.changeHandler} type="password" className="form-control" id="currentPassword" aria-describedby="emailHelp" placeholder="Nhập mật khẩu hiện tại" />
              </div>
              <div className="form-group">
                <label htmlFor="newPassword">Mật khẩu mới</label>
                <input name="newPassword" required value={newPassword} onChange={this.changeHandler} type="password" className="form-control" id="newPassword" aria-describedby="emailHelp" placeholder="Nhập mật khẩu mới" />
              </div>
              <div className="form-group">
                <label htmlFor="newPasswordConfim">Nhập lại mật khẩu mới</label>
                <input name="newPasswordConfim" required value={newPasswordConfim} onChange={this.changeHandler} type="password" className="form-control" id="newPasswordConfim" aria-describedby="emailHelp" placeholder="Nhập lại mật khẩu mới" />
              </div>
              <button type="submit" className="btn btn-primary">Đổi mật khẩu</button>
              {notice}
              {/* <button type="button" className="btn btn-primary">Trang chủ</button> */}
            </form>
         </div> )  ;
    }
    render(){
      const {
        userName,
        currentPassword,
        newPassword,
        newPasswordConfim,
         softId
      } = this.state.change;
      const notice= this.state.notice;
      //const recaptchaRef = React.createRef();
        return (
         
        <div className="container" style={{ marginTop: "90px" , minHeight:"68vh", marginBottom: "20px" , width: "800px" }}>
          <Helmet>
            <title>{configData.CompanyName} - Quên mật khẩu</title>
            <meta name="description" content={ configData.Description } />
          </Helmet>
            <form action="/" onSubmit={this.submitHandler}>
              <h1>Đổi mật khẩu</h1>
              <p>Vui lòng cung cấp Mật khẩu đang sử dụng và Mật khẩu mới để thay đổi mật khẩu.</p>
              <div className="form-group">
                <label htmlFor="exampleInputEmail1">Tên đăng nhập/Email</label>
                <input name="userName" readOnly  required value={userName} onChange={this.changeHandler} type="text" className="form-control" id="exampleInputEmail1" aria-describedby="emailHelp" placeholder="Nhập tên đăng nhập hoặc Email đăng ký" />
              </div>
              <div className="form-group">
                <label htmlFor="currentPassword">Mật khẩu hiện tại</label>
                <input name="currentPassword" required value={currentPassword} onChange={this.changeHandler} type="password" className="form-control" id="currentPassword" aria-describedby="emailHelp" placeholder="Nhập mật khẩu hiện tại" />
              </div>
              <div className="form-group">
                <label htmlFor="newPassword">Mật khẩu mới</label>
                <input name="newPassword" required value={newPassword} onChange={this.changeHandler} type="password" className="form-control" id="newPassword" aria-describedby="emailHelp" placeholder="Nhập mật khẩu mới" />
              </div>
              <div className="form-group">
                <label htmlFor="newPasswordConfim">Nhập lại mật khẩu mới</label>
                <input name="newPasswordConfim" required value={newPasswordConfim} onChange={this.changeHandler} type="password" className="form-control" id="newPasswordConfim" aria-describedby="emailHelp" placeholder="Nhập lại mật khẩu mới" />
              </div>
              <button type="submit" className="btn btn-primary">Đổi mật khẩu</button>
              {notice}
              {/* <button type="button" className="btn btn-primary">Trang chủ</button> */}
            </form>
         </div>   
           
        );
    }

}

export default ChangePass;
