import React from 'react';
import { Link } from 'react-router-dom';
import { any } from 'prop-types';
import Header from './Header';
import RouterUrl from '../Commons/RouterUrl';
import Footer from './Footer';
import configData from '../../config.json';
import {Helmet} from 'react-helmet'; 


class TrangChu extends React.Component<any, any> {
	
	constructor(props:any) {
        super(props);
		this.state = {
		}
	}
    render(){
        return (
            <div>
				<Helmet>
					<title>{configData.CompanyName} - {configData.Sologan}</title>
					<meta name="description" content={ configData.Description } />
            	</Helmet>
				<div id="preloder">
					<div className="loader"></div>
				</div>
				<Header/>
				{/*<ImageView t="9001" th="0" url="meo.jpg" width="" height="300" alt="SaigonIT"/>*/}
				<RouterUrl/>   
				{/*<Footer/>*/}
	{/* <script src="../js/jquery-3.2.1.min.js"></script>
	<script src="../js/bootstrap.min.js"></script>
	<script src="../js/jquery.slicknav.min.js"></script>
	<script src="../js/owl.carousel.min.js"></script>
	<script src="../js/mixitup.min.js"></script>
	<script src="../js/main.js"></script> */}
            </div>
        );
    }

}
export default TrangChu;
