import React from 'react';
import '../SanPham/product.css';

class ProductsHeader extends React.Component<any, any> {
	
	constructor(props:any) {
        super(props);
	}
	componentDidMount() {
        
      }
	componentWillMount(){
	}
    render(){
        return (
            <div className="container-flud d-flex justify-content-center">
                <div className="elementor-widget-container">
                    <div className="ot-heading">
                        <span>Sản phẩm mới nhất</span>
                        <h2 className="main-heading">Công Ty Cổ Phần Tin học SaigonIT</h2>	  
                    </div>
                    <p>Mô hình phát triển của SaigonIT dựa trên nền tảng vững chắc Nhu cầu của doanh nghiệp  + Cơ sở lý thuyết + Chuyên gia Kinh tế <br/>+ Chuyên gia CNTT đem lại cho khách hàng giải pháp Đơn giản - Chuyên nghiệp - Hiệu quả.
                    </p>
                </div>
            </div>
        );
    }
}
export default ProductsHeader;

