import React from 'react';
import UrlService from '../../services/UrlService';
import BootstrapTable from 'react-bootstrap-table-next';
import cellEditFactory , { Type } from 'react-bootstrap-table2-editor';
import filterFactory, { textFilter } from 'react-bootstrap-table2-filter';
import paginationFactory from 'react-bootstrap-table2-paginator';
import CookieService from '../../services/CookieService';
import { Button, Card, ListGroup, ListGroupItem } from 'react-bootstrap';
import {  Container, Row,Col } from 'react-bootstrap';
import { TiDeleteOutline } from 'react-icons/ti';
import BreadCrumbNews from '../Commons/BreadCrumbNews';
import '../GioHang/GioHang.css'; 
import axios from 'axios';
import configData from '../../config.json';
import queryString from 'query-string';
import AccountService from '../../services/AccountService';
import { data } from 'jquery';
import moment from 'moment';

class ChiTietTaiKhoan extends React.Component<any, any> {
	node: any;
	constructor(props:any) {
        super(props);
		this.state = {
			dataJs: {},
			orderDetail: [],
			licenseRequest: { },
			updateUserRequest:{}
		}
		// this.deleteProduct = this.deleteProduct.bind(this);
	}
	componentDidMount() {
		const query= queryString.parse(window.location.search);
        const Token= AccountService.Token();
		//const Roles= AccountService.Roles();
		if(Token)
		{
			fetch(
				configData.serviceUrl + '/Admin/GetAccountInfo?UserName='+ query.u+'&SoftId='+ configData.SoftId,
				{headers:new Headers ({
					'Access-Control-Allow-Origin' : '*',
					'Authorization' :  CookieService.get("access_token") ,
				})}
			) 
				.then(response => response.json())
				.then(dataJs => {
					if(dataJs.sErr==null)
					{
						this.setState({
						dataJs: dataJs
					});
					}
				})
				.catch(e => {
					console.log(e);
				});

				//fetch(
				//	configData.serviceUrl+ '/api/Admin/GetOrderDetail?NO_VOU='+ query.id,
				//	{headers:new Headers ({
				//		'Access-Control-Allow-Origin' : '*',
				//		'Authorization' :  CookieService.get("access_token") ,
				//	})}
				//) 
				//	.then(response => response.json())
				//	.then(orderDetail => {
				//		if(orderDetail.Status==200)
				//		{
				//			this.setState({
				//			orderDetail: orderDetail.Data
				//		});
				//		}
				//	})
				//	.catch(e => {
				//		console.log(e);
				//	});
		}
		else	
		{
			window.location.href= UrlService.UrlRoot()+"/DangNhap";
		}
        
      }

	componentWillMount(){
		
	}
	capBanQuyen1Nam = (e: any) => {
		//e.preventDefault();
		let licenseRequest = this.state.licenseRequest;
		licenseRequest["UserName"] = this.state.dataJs.userName;
		licenseRequest["SoftId"] = this.state.dataJs.softId;
		licenseRequest["IsOneYear"] = true;
		licenseRequest["DateTo"] = null;
		licenseRequest["ExpireMinutes"] = 20000;
		licenseRequest["IsPerpetual"] = null;
		licenseRequest["MultipleDevice"] = true;
		licenseRequest["NumberOfDevice"] = 1000;
		this.setState({ licenseRequest: licenseRequest });
		//console.log(this.state.dataJs);
		this.capBanQuyen();
	}
	capBanQuyenVinhVien = (e: any) => {
		//e.preventDefault();
		let licenseRequest = this.state.licenseRequest;
		licenseRequest["UserName"] = this.state.dataJs.userName;
		licenseRequest["SoftId"] = this.state.dataJs.softId;
		licenseRequest["IsOneYear"] = null;
		licenseRequest["DateTo"] = null;
		licenseRequest["ExpireMinutes"] = 20000;
		licenseRequest["IsPerpetual"] = true;
		licenseRequest["MultipleDevice"] = true;
		licenseRequest["NumberOfDevice"] = 1000;
		this.setState({ licenseRequest: licenseRequest });
		//console.log(this.state.dataJs);
		this.capBanQuyen();
	}
	capBanQuyen() {
		axios.post(configData.serviceUrl + '/Admin/SetLicense', this.state.licenseRequest,
			{
				headers: {
					'Content-Type': 'application/json',
					'Access-Control-Allow-Origin': '*',
					'Authorization': CookieService.get("access_token"),
				}
			}).then(response => {
				//console.log(response.data);
				if (response) {
					if (response.status === 200) {
						if (response.data.sErr && response.data.sErr.errorCode != 0) {
							alert("Cấp bản quyền thất bại! Lỗi: " + response.data.sErr.errorMessage);
						}
						else {
							//console.log(response.data.Data);
							alert("Cấp bản quyền thành công!");
							window.location.reload();
						}

					}
					else {
						alert("Cấp bản quyền thất bại! Lỗi: " + response.data.sErr.errorMessage);
					}
				}
				else {
					alert("Cấp bản quyền thất bại!");
				}
			})
			.catch(error => {
				console.log(error);
				alert(error);
			});
	}
	
	khoa = (e: any) => {
		let updateUserRequest = this.state.updateUserRequest;
		updateUserRequest["UserName"] = this.state.dataJs.userName;
		updateUserRequest["SoftId"] = this.state.dataJs.softId;
		updateUserRequest["Visible"] = false;
		this.setState({ updateUserRequest: updateUserRequest });
		//console.log(this.state.dataJs);
		this.updateUser();
	}
	moKhoa = (e: any) => {
		let updateUserRequest = this.state.updateUserRequest;
		updateUserRequest["UserName"] = this.state.dataJs.userName;
		updateUserRequest["SoftId"] = this.state.dataJs.softId;
		updateUserRequest["Visible"] = true;
		this.setState({ updateUserRequest: updateUserRequest });
		//console.log(this.state.dataJs);
		this.updateUser();
	}
	updateUser() {
		axios.post(configData.serviceUrl + '/Admin/UpdateUser', this.state.updateUserRequest,
			{
				headers: {
					'Content-Type': 'application/json',
					'Access-Control-Allow-Origin': '*',
					'Authorization': CookieService.get("access_token"),
				}
			}).then(response => {
				//console.log(response.data);
				if (response) {
					if (response.status === 200) {
						if (response.data.sErr && response.data.sErr.errorCode != 0) {
							alert("Cập nhật thông tin tài khoản thất bại! Lỗi: " + response.data.sErr.errorMessage);
						}
						else {
							//console.log(response.data.Data);
							//alert("Cập nhật thông tin tài khoản thành công!");
							window.location.reload();
						}

					}
					else {
						alert("Cập nhật thông tin tài khoản thất bại! Lỗi: " + response.data.sErr.errorMessage);
					}
				}
				else {
					alert("Cập nhật thông tin tài khoản thất bại!");
				}
			})
			.catch(error => {
				console.log(error);
				alert(error);
			});
	}
	actionsFormatter (cell, row ,rowIndex, {parents})
	{
		return <ul className="list-inline m-0">
	
		<li className="list-inline-item">
			<button className="btn btn-danger btn-sm rounded-0" onClick={ () => 
			{
				console.log(row.NO_VOU);
				console.log(parents[rowIndex].NO_VOU);
				window.location.href = UrlService.UrlRoot()+"/DonHang?id=" + row.NO_VOU;
			}
		}
			type="button" data-toggle="tooltip" data-placement="top" title="Chi tiết đơn hàng">
			<i className="fa fa-external-link"></i>
			</button>
		</li>
	</ul>
	}
    render(){
		// let dataCookie= CookieService.get("orderData");
		// this.setState({ dataJs : dataCookie});
		const dataJs= this.state.dataJs;
		//const orderDetail=this.state.orderDetail;
		//console.log(dataJs);
		function priceFormatter(cell, row) {
			return (
			  <span>{parseInt(cell).toLocaleString() }</span>
			);
		}
		 
        const columns = [
			{
                dataField: 'code',
                text: 'Mã sản phẩm',
                sort: true,
				editable: false
            },
            {
			dataField: 'name',
			text: 'Tên sản phẩm',
			editable: false
			}
			,{
				dataField: 'quantity',
				text: 'Số lượng',
				type: 'number',
				editorStyle: {
					backgroundColor: '#007bff',
					color:'#f6f6f6'
				  },
				validator: (newValue, row, column) => {
					if (isNaN(newValue)) {
					  return {
						valid: false,
						message: 'Chưa nhập số lượng'
					  };
					}
					if (newValue <= 0) {
					  return {
						valid: false,
						message: 'Chưa nhập số lượng'
					  };
					}
					return true;
				  }
			} 
			,{
				dataField: 'price',
				text: 'Đơn giá',
				editable: false,
				formatter: priceFormatter
			}
			,{
				dataField: 'total',
				text: 'Thành tiền',
				editable: false,
				formatter: priceFormatter
			}
			// ,{
			// 	dataField: 'actions',
			// 	text: 'Thao tác',
			// 	isDummyField: true,
			// 	csvExport: false,
			// 	formatter: this.actionsFormatter,
			// 	formatExtraData: { parents: this.state.dataJs },
			// 	editable: false
			//   }
            
		];

	 
		const rowStyle = { height: "20px" };
		const accVisible = { color: "green" };
		const accInVisible = { color: "red" };
		  const btnKhoa=<Button style={{ marginLeft:"5px" }} variant="primary" type="submit" onClick={this.khoa}  >
		  Khóa tài khoản
	  		</Button>;
			const btnMoKhoa=<Button style={{ marginLeft:"5px" }} variant="primary" type="submit" onClick={this.moKhoa}  >
			Mở khóa tài khoản
				</Button>;
		  function afterSaveCell(oldValue, newValue, row, column) {
			console.log(row);
			console.log(column);
			try{
				 
				
			}
			catch(err) {
				console.log("Lỗi lưu: "+err);
			}
		}

        return (
			<div className="_container"  >
			<section className="inner-page">
			   <div style={{paddingTop: '90px', paddingBottom:'110px'}}>
			   
			   <Container >
				   <Row>
				   <div className="card" style={{width: '100%'}}>
						<div className="card-header">
						Thông tin tài khoản
						</div>
						<div>
							<Container>
								<Row style={{ height: "40px" }}>
									<Col lg={6} sm={12}>
										Tên đăng nhập: <b> { dataJs.userName }</b>
									</Col>
									<Col lg={6} sm={12}>
										Email: <b>{  dataJs.email }</b>
									</Col>
								</Row>
								<Row style={{ height: "40px" }}>
									<Col lg={6} sm={12}>
									Điện thoại: <b>{ dataJs.phone}</b>
									</Col>
									<Col lg={6} sm={12}>
													Tình trạng bản quyền: <b style={dataJs.isApprove ? accVisible : accInVisible}>{dataJs.isApprove ? "Đã cấp quyền" : "Chưa đăng ký bản quyền"}</b>
									</Col>
								</Row>
								<Row style={{ height: "40px" }}>
									<Col lg={6} sm={12}>
									Ngày đăng ký: <b>{ moment(dataJs.dateFrom).format("DD/MM/YYYY HH:mm")  }</b>
									</Col>
									<Col lg={6} sm={12}>
									Hạn sử dụng: <b>{moment(dataJs.dateTo).format("YYYY").toString()=="2100"? "Bản quyền vĩnh viễn" : moment(dataJs.dateTo).format("DD/MM/YYYY HH:mm")}</b>
									</Col>
											</Row>

											<Row style={{ height: "40px" }}>

									<Col lg={6} sm={12}>
													Đăng nhập lần cuối: <b>{dataJs.lastLogin!=null? moment(dataJs.lastLogin).format("DD/MM/YYYY HH:mm") : "Chưa đăng nhập lần nào"}</b>
									</Col>
									<Col lg={6} sm={12}>
									Tình trạng tài khoản: <b style={dataJs.visible ? accVisible: accInVisible }>{dataJs.visible ?  "Đang hoạt động" : "Đã khóa"}</b>
												{dataJs.visible? btnKhoa : btnMoKhoa }	
									</Col>
											</Row>
											<Row style={{ height: "40px" }}>
												<Col lg={6} sm={12}>
													Loại bản quyền: <b>{dataJs.MultipleDevice ? "Doanh nghiệp" : "Cá nhân"}</b>
												</Col>
												<Col lg={6} sm={12}>
													Số máy đăng ký: <b>{dataJs.numberOfDevice != null ? dataJs.numberOfDevice == 1000 ? "Không giới hạn" : dataJs.numberOfDevice : 1} máy</b>
												</Col>
											</Row>
											<Row style={{ minHeight: "60px" , marginTop:"20px" }}>
												<Col lg={4} sm={12} style={{ marginBottom:"5px" } }>
													<Button variant="primary" type="submit" onClick={this.capBanQuyen1Nam}  >
														Cấp bản quyền 1 năm
													</Button>
													{/*<a className="btn btn-primary" href={UrlService.UrlRoot() + "/CapBanQuyen?t=1"} role="button">Cấp bản quyền 1 năm</a>*/}
												</Col>
												<Col lg={4} sm={12} style={{ marginBottom: "5px" }}>
													<Button variant="primary" type="submit" onClick={this.capBanQuyenVinhVien}  >
														Cấp bản quyền vĩnh viễn
													</Button>
													{/*<a className="btn btn-primary" href={UrlService.UrlRoot() + "/CapBanQuyen?t=2"} role="button">Cấp bản quyền vĩnh viễn</a>*/}
												</Col>
												<Col lg={4} sm={12} style={{ marginBottom: "5px" }}>
													{/*<Button variant="primary" type="submit" href ={ UrlService.UrlRoot() + "/CapBanQuyen"}  >*/}
													{/*	Cấp bản quyền theo ngày*/}
													{/*</Button>*/}
													<a className="btn btn-primary" href={UrlService.UrlRoot() + "/CapBanQuyen?u=" + dataJs.userName} role="button">Cấp bản quyền theo ngày</a>
												</Col>
											</Row>
							</Container>
						</div>
						{/* <ul className="list-group list-group-flush">
							<li className="list-group-item">
								Mã đơn hàng: {  dataJs.ID_VOU }
							</li>
							<li className="list-group-item">Ngày đặt hàng: { moment(dataJs.DATE_SYS).format("DD/MM/YYYY HH:mm")  }</li>
							<li className="list-group-item">Tình trạng: {dataJs.NAME_STA}</li>
						</ul> */}
					</div>
				   </Row>
				   <Row>
				   <a className="btn btn-success mt-2" href={ UrlService.UrlRoot()+"/TaiKhoan" } role="button">Quay lại</a>
				   </Row>
			   </Container>
			   
			   </div>
			 </section>
		 </div>
			 
        );
    }

}

export default ChiTietTaiKhoan;
