import React from 'react';
import { Link } from 'react-router-dom';
import configData from "../../config.json";
import CookieService from '../../services/CookieService';
import { any, number } from 'prop-types';
import UrlService from '../../services/UrlService';
import AccountService from '../../services/AccountService';

class Header extends React.Component<any, any> {
	
	constructor(props:any) {
        super(props);
		this.state = {
			lstMenu: [
				{
					ID_FUN: 0,
					ID_OGR: 0,
					ID_OTY: 0,
					NAME_FUN: "",
					NAME_OGR: "",
					LINK: "",
					ModuleName: "",
					lstSubs: [
					  
					]
				  }
			],
			showLogin: false,
			ID_OTY: false,
			checkLogin1: false,
			checkLogin2: true
		}
	}

	componentDidMount() {
        //fetch(configData.serviceUrl+ '/api/News/MenuBar',
        //{headers:new Headers ({
        //    'Access-Control-Allow-Origin' : '*'
        //})}
        //)
        //    .then(response => response.json())
        //    .then(lstMenu => this.setState({lstMenu : lstMenu.Data}))
        //    .catch(e => {
        //        console.log(e);
        //        return e;
        //    });
      }
	componentWillMount(){
	}
	checkLogin(){
		
	}
	Logout=()=>{
        CookieService.remove("access_token");
        window.location.href= UrlService.UrlRoot();
    }
    render(){
		const lstMenu =  this.state.lstMenu;
		const Token = CookieService.get("access_token");
		const Roles = AccountService.Roles();
		const DisplayName = CookieService.get("DisplayName");
        let Account;
        if(Token)
        {
            // Account= <div> <span>Xin chào, { CookieService.get("DisplayName") } |  <a href="#" onClick ={()=>this.Logout()}>Đăng xuất</a></span></div>
			Account=<div className="navbar-nav ml-auto">
			<i className="bi bi-person-circle" style={{fontSize: '24px', color: 'red'}}></i>
			<li className="nav-item dropdown">
			
									<a className="nav-link dropdown-toggle" href="#" id="navbarDropdownMenuLink" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
									{ CookieService.get("DisplayName") }
									</a>
									<div className="dropdown-menu" aria-labelledby="navbarDropdownMenuLink">
									{Roles && Roles.includes("G9998")? <a className="nav-item nav-link active" href="../Admin">Quản trị</a> : null } 
						{/*<a className="nav-item nav-link active" href="../DonHang">Quản lý đơn hàng</a>*/}
						<a className="nav-item nav-link active" href="../TaiKhoan">Quản lý tài khoản</a>
						<a className="nav-item nav-link active" href="../DoiMatKhau">Đổi mật khẩu</a>
										<a className="nav-item nav-link active" href="#" onClick ={()=>this.Logout()}>Đăng xuất</a>
										
									</div>
								</li>
			</div>
        }
        else
        {
            Account=<div className="navbar-nav ml-auto">
			<a className="nav-item nav-link active" href="../DangNhap">Đăng nhập</a>
			{/*<a className="nav-item nav-link active" href="../DangKy">Đăng ký</a>*/}
		</div>
        }
        return (
			<header id="header" className="fixed-top header-inner-pages">
				<div className="container">
					<nav className="navbar navbar-expand-lg navbar-dark" style={ { backgroundColor: "transparent" } }>
					<a href="/" className="logo mr-auto"><img src="../assets/img/logo-header.png" alt="" className="img-fluid mr-5"  /></a>
					<button className="navbar-toggler" type="button" data-toggle="collapse" data-target="#navbarNavDropdown" aria-controls="navbarNavDropdown" aria-expanded="false" aria-label="Toggle navigation">
						<span className="navbar-toggler-icon"></span>
					</button>
					<div className="collapse navbar-collapse" id="navbarNavDropdown">
						{/*<ul className="navbar-nav">*/}
						{/*	{*/}
						{/*		lstMenu.map((link:any) => link.lstSubs && link.lstSubs.length>0? */}
						{/*		<li key={ link.LINK.toString() } className="nav-item dropdown">*/}
						{/*			<a className="nav-link dropdown-toggle" href="#" id="navbarDropdownMenuLink" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">*/}
						{/*				{link.NAME_FUN}*/}
						{/*			</a>*/}
						{/*			<div className="dropdown-menu" aria-labelledby="navbarDropdownMenuLink">*/}
						{/*				{*/}
						{/*					link.lstSubs.map((sub:any) => <a key={ sub.LINK.toString() } className="dropdown-item" href={UrlService.UrlRoot() + sub.LINK}>{sub.NAME_OGR}</a>)*/}
						{/*				}*/}
						{/*			</div>*/}
						{/*		</li>*/}
						{/*		 : <li className="nav-item" key={ link.LINK.toString() }><a className="nav-link" href={ UrlService.UrlRoot() + link.LINK }>{link.NAME_FUN}</a></li> )*/}
						{/*	} */}
						{/*	</ul>*/}
							<ul className="navbar-nav">
									
										{/*<li className="nav-item dropdown">*/}
										{/*	<a className="nav-link dropdown-toggle" href="#" id="navbarDropdownMenuLink" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">*/}
										{/*		{link.NAME_FUN}*/}
										{/*	</a>*/}
										{/*	<div className="dropdown-menu" aria-labelledby="navbarDropdownMenuLink">*/}
										{/*		{*/}
										{/*			link.lstSubs.map((sub: any) => <a key={sub.LINK.toString()} className="dropdown-item" href={UrlService.UrlRoot() + sub.LINK}>{sub.NAME_OGR}</a>)*/}
										{/*		}*/}
										{/*	</div>*/}
										{/*</li>*/}
								<li className="nav-item"><a className="nav-link" href={UrlService.UrlRoot()}>Trang chủ</a></li>
								<li className="nav-item"><a className="nav-link" href="https://softviet.vn">Liên hệ</a></li>
							</ul>
						{ Account }
					</div>
					</nav>
				</div>
		  	</header>
        );
    }

}

export default Header;
