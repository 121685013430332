import React from 'react';
import { any, number } from 'prop-types';
import axios from 'axios';
import configData from '../../config.json';
import ReCAPTCHA from "react-google-recaptcha";
import UrlService from '../../services/UrlService';
import {Helmet} from 'react-helmet'; 

class ChangePassSuccess extends React.Component<any, any> {
  private dateValue:Date=new Date();
  private pass2Style;
    constructor(props: any) {
        super(props);
        this.state = {
          loss : {
            Email: '',
            Token:''
          },
            notice: any
        };
        
    }
    homePage()
    {
      window.location.href = UrlService.UrlRoot();
    }
    render(){
        return (
        <div className="container" style={{ marginTop: "90px" , minHeight:"70vh", marginBottom: "20px" , width: "800px" }}>
          <Helmet>
            <title>{configData.CompanyName} - Đổi mật khẩu</title>
            <meta name="description" content={ configData.Description } />
          </Helmet>
            <div className="card">
                <h5 className="card-header">Đổi mật khẩu</h5>
                <div className="card-body">
                    <p className="card-text">Đổi mật khẩu thành công!</p>
                    <button type="submit" className="btn btn-primary" onClick={ this.homePage}>Trang chủ</button>
                </div>
                
            </div>
         </div>   
        );
    }
}
export default ChangePassSuccess;
