import React from 'react';
import axios from 'axios';
import './Login.css';
import { BrowserRouter as Router, Route, Link, RouteComponentProps } from "react-router-dom";
import { any, number } from 'prop-types';
import configData from "../../config.json";
import CookieService from '../../services/CookieService';
import UrlService from '../../services/UrlService';
import LibService from '../../services/LibService';
import { Col, Container, Row } from 'react-bootstrap';
import { Column } from '@syncfusion/ej2-grids';
import {Helmet} from 'react-helmet'; 

class Login extends React.Component<RouteComponentProps, any> {
    constructor(props: RouteComponentProps) {
        super(props);
        this.state = { Users: { userName: "", password: "", rememberMe: true, softId: configData.SoftId, hardKey: LibService.GenId(10) } };
         this.handleChangeUserName = this.handleChangeUserName.bind(this);
         this.handleChangePassWord = this.handleChangePassWord.bind(this);
         this.handleChangeRememberMe = this.handleChangeRememberMe.bind(this);
         this.submitHandler = this.submitHandler.bind(this);
      }
      ClearCookie()
      {
        CookieService.remove("access_token");
        CookieService.remove("Username");
        CookieService.remove("Uid");
        CookieService.remove("DisplayName");
      }
      submitHandler(evt: any) {
        evt.preventDefault();
        axios.post(configData.serviceUrl+ '/Users/Authenticate', this.state.Users,
        { headers: { 'Content-Type': 'application/json' ,
                     'Access-Control-Allow-Origin' : '*'
                   }
        }).then(response =>{
            console.log(response.data);
            if(response)
            {
                if(response.status===200)
                {
                    if (response.data.sErr && response.data.sErr.errorCode != 0) {
                        this.ClearCookie();
                        alert("Đăng nhập thất bại! " + response.data.sErr.errorMessage);
                    }
                    else {
                        //console.log(response.data.Data);
                        let expires = new Date();
                        let options = { path: '/', expires };
                        expires.setTime(expires.getTime() + (this.state.Users.rememberMe === false ? configData.RememberLoginTime * 60 * 1000 : 2147483647));
                        CookieService.set("access_token", response.data.token, options);
                        CookieService.set("Username", response.data.userName, options);
                        CookieService.set("Uid", response.data.userId, options);
                        CookieService.set("DisplayName", response.data.userName + '(' + response.data.email + ')', options);
                        CookieService.set("Email", response.data.email, options);
                        //CookieService.set("Roles", response.data.Data.Roles, options);
                        window.location.href = UrlService.UrlRoot();
                    }
                    
                }
                else    
                {
                    this.ClearCookie();
                    alert("Đăng nhập thất bại. Tên đăng nhập hoặc mật khẩu chưa đúng!");
                }
            }
            else
            {
                this.ClearCookie();
                alert("Đăng nhập thất bại. Tên đăng nhập hoặc mật khẩu chưa đúng!");
            }
        })
        .catch(error =>{
            console.log(error);
            alert(error);
        })
      }
      handleChangeUserName(event: any) {
        let Users = this.state.Users;
        Users["userName"] = event.target.value;
        this.setState({Users : Users});
      }
      handleChangeRememberMe(event: any) {
        let Users = this.state.Users;
        Users["rememberMe"] = event.target.value;
        this.setState({Users : Users});
      }
      handleChangePassWord(event: any) {
        let Users = this.state.Users;
        Users["password"] = event.target.value;
        this.setState({Users : Users});
      }
      submitHandlerCall=()=>{
      }
      componentDidMount() {
      }
    render(){
        return (
            <div className="container"  >
                <Helmet>
					<title>{configData.CompanyName} - Đăng nhập</title>
					<meta name="description" content={ configData.Description } />
            	</Helmet>
                <section className="inner-page" style={{ marginTop: "72px" }}>
                    <form className ="login-form" onSubmit={this.submitHandler} >
                        <Container>
                            <Row>
                                    <div className="col-lg-4">
                                        <div className="imgcontainer">
                                            <div className="avatar"></div>
                                        </div>
                                    </div>
                                    <div className="col-lg-8">
                                        <Container>
                                                <Row>
                                                    <label ><b>Tên đăng nhập</b></label>
                                                </Row>
                                                <Row>
                                                    <input type="text" placeholder="Điền tên đăng nhập" id="theInput" value={this.state.Users.userName} onChange={this.handleChangeUserName}/>
                                                </Row>
                                                <Row>
                                                    <label ><b>Mật khẩu</b></label>
                                                </Row>
                                                <Row>
                                                    <input type="password" placeholder="Điền mật khẩu" id="theInput" value={this.state.Users.password} onChange={this.handleChangePassWord}/>
                                                </Row>
                                                <Row>
                                                    <div className="col-md-7 m-0 p-0">
                                                        <div className="icheck-primary">
                                                            <input type="checkbox" id="remember" onChange={ this.handleChangeRememberMe} checked={this.state.Users.rememberMe} />
                                                            <label onClick={this.handleChangeRememberMe} id="remember-label">
                                                                Nhớ đăng nhập
                                                            </label>
                                                        </div>
                                                    </div>
                                                        <div className="col-md-5 p-0 d-flex justify-content-end">
                                                            <button type="submit" className="btn btn-primary" onClick ={()=>this.submitHandlerCall()}>
                                                                Đăng nhập
                                                            </button>
                                                        </div>
                                                </Row>
                                            </Container>
                                        <Container>
                                            <Row>
                                                {/*<div className="col-md-5 ml-0 pl-0 d-flex justify-content-start">*/}
                                                {/*    <Link to="../DangKy">Đăng ký tài khoản</Link>*/}
                                                {/*</div>*/}
                                                <div className="col-md-7 ml-0 pl-0 d-flex justify-content-start">
                                                    <Link to="../QuenMatKhau">Quên mật khẩu</Link>
                                                </div>
                                            </Row>
                                        </Container>
                                    </div>
                            </Row>
                        </Container>
                    </form>
                </section>
            </div>
        );
    }

}

export default Login;
